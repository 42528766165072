// extracted by mini-css-extract-plugin
export var topArticles = "style-mod--top-articles--1n6pf";
export var articleContentFirst = "style-mod--article-content-first--MvsjL";
export var acTitle = "style-mod--ac-title--1hdJL";
export var acSubtitle = "style-mod--ac-subtitle--23ACF";
export var articleSlider = "style-mod--article-slider--19xm2";
export var articleContent = "style-mod--article-content--26Llu";
export var articleCard = "style-mod--article-card--38jSR";
export var articleImg = "style-mod--article-img--1wKWk";
export var articleCardBody = "style-mod--article-card-body--3qEX3";
export var articleCardContent = "style-mod--article-card-content--2rhVP";
export var articleCardTitle = "style-mod--article-card-title--35oKk";
export var articleCardLink = "style-mod--article-card-link--1BKYA";