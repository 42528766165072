import React from "react";
import {
  Card, Row, Col, Container
} from "react-bootstrap";
import Slider from "react-slick";
import {
  topArticles, articleSlider, articleContent, acTitle,
  acSubtitle, articleCard, articleImg, articleCardBody,
  articleCardContent, articleCardTitle, articleCardLink,
  articleContentFirst
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";
import ArrowRight from "assets/svgs/arrow-right.svg";

const TopArticles = ({ data }) => {

  const { title, subtitle, article } = data ;

  console.log(data.article);
  const d = data.article;
  const articleImage = d.articleImage;


  var settings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          autoplay: true,
          infinite: true,
          dots: true,
          centerPadding: "65px",
          slidesToShow: 1,
          slidesToScroll:1,
          initialSlide: 1,
          rows: 1,
          slidesPerRow: 1,
        }
      },
    ]
  };
  return (
    <div className={topArticles}>
      <Container>
        <div className={articleContentFirst}>
          <p className={acTitle}>{title}</p>
          <p className={acSubtitle}>{subtitle}</p>
        </div>
        <Row>
          <Slider {...settings} className={articleSlider}>
            {article.map((d, index) => {
              return(
                (index == 1 ?   <div key={index}> <Col >
                  <div className={articleContent}>
                    <p className={acTitle}>{title}</p>
                    <p className={acSubtitle}>{subtitle}</p>
                  </div>
                  <a href={d.articleLink}>
                    <Card className={articleCard}>
                      <Card.Img className={articleImg} srcSet={getImagePath(d.articleImage, true)} alt={d.articleImage?.alt}/>
                      <Card.Body className={articleCardBody}>
                        <div className={articleCardContent}>
                          <Card.Title className={articleCardTitle}>{d.articleTitle}
                            <span className={articleCardLink} href={d.articleLink}>
                              <ArrowRight/>
                            </span>
                          </Card.Title>
                        </div>
                      </Card.Body>
                    </Card>
                  </a>
                </Col></div>  : <div key={index}><Col>
                  <a href={d.articleLink} >
                    <Card className={articleCard}>
                      <Card.Img className={articleImg}  srcSet={getImagePath(d.articleImage, true)} alt={articleImage?.alt} />
                      <Card.Body className={articleCardBody}>
                        <div className={articleCardContent}>
                          <Card.Title className={articleCardTitle}>{d.articleTitle}
                            <span className={articleCardLink} href={d.articleLink}>
                              <ArrowRight/>
                            </span>
                          </Card.Title>
                        </div>
                      </Card.Body>
                    </Card>
                  </a>
                </Col></div>  )
              );
            })}
          </Slider>
        </Row>
      </Container>
    </div>
  );
};

export default TopArticles;
